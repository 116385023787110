/*
CSS for index.html
@hugoalexandremf
*/

/*
----------------------------------------------------NavBar----------------------------------------------------
*/

/* Add a black background color to the top navigation */
.topnav {
    background-color: black;
    overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
    float: right;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
    background-color: #ddd;
    color: black;
}

/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
    display: none;
}

/*
----------------------------------------------------NavBar----------------------------------------------------
*/

#myImg{
	border-radius: 12px;
	margin-left: 7%;
	margin-top: 6%;
}

/*
----------------------------------------------------Neon Lights----------------------------------------------------
*/

@import url(//fonts.googleapis.com/css?family=Vibur);
/*
html,body{
  height:100%
}

body {
  background: #112 url(//images.weserv.nl/?url=i.imgur.com/6QJjYMe.jpg)  center no-repeat;
  background-size:cover;
  margin:0
}
*/
.logo {
	width: 87%;
	height: 77%;
	margin: auto;
}

.logo b{
  font: 100 3vh "Vibur";
  color: #d3e3ff; /*#fee*/
  text-shadow: 0 -40px 100px, 0 0 2px, 0 0 1em #ffdb44, 0 0 0.5em #ffdb44, 0 0 0.1em #ffdb44, 0 10px 3px #000; /*#ff4444*/
}

.logo b span{
  /*animation: blink linear infinite 2s;*/
}
@keyframes blink {
  78% {
    color: inherit;
    text-shadow: inherit;
  }
  79%{
     color: #333;
  }
  80% {
    
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: inherit;
  }
  82% {
    color: #333;
    text-shadow: none;
  }
  83% {
    color: inherit;
    text-shadow: inherit;
  }
  92% {
    color: #333;
    text-shadow: none;
  }
  92.5% {
    color: inherit;
    text-shadow: inherit;
  }
}


/* follow me @nodws */
#btn-twtr{
  clear:both;
  color:#fff;
  border:2px solid;
  border-radius:3px;
  text-align:center;
  text-decoration:none;
  display:block;
  font-family:sans-serif;
  font-size:14px;
  width:13em;
  padding:5px 10px;
  font-weight:600;
  position:absolute;
  bottom:20px;
  left:0;
  right:0;
  margin:0 auto;
  background:rgba(0,0,0,0.2);
  box-shadow:0 0 0px 3px rgba(0,0,0,0.2);
  opacity:0.4
}#btn-twtr:hover{color:#fff;opacity:1}

/*
----------------------------------------------------Neon Lights----------------------------------------------------
*/

/*
----------------------------------------------------Table----------------------------------------------------
*/
#experienceTable{
  width: 100%; 
  border-collapse: collapse; 
}

td, th { 
  padding: 16px; 
  /*border: 1px transparent #ccc;*/
  text-align: center; 
}

@media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 600px)  {
  table, thead, tbody, th, td, tr { 
    display: block; 
  }

  tr { 
    /*border: 1px solid #ccc;*/
  }
  
  td { 
    /* Behave  like a "row" */
    border: none;
    /*border-bottom: 1px solid #eee;*/
    position: relative;
    padding-left: 50%; 
  }
  td:before { 
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%; 
    padding-right: 10px; 
    white-space: nowrap;
  }
}
/*
----------------------------------------------------Table----------------------------------------------------
*/