/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Sep 23, 2021, 10:15:32 PM
    Author     : hugoalexandremf
*/

body {
    background-color: #ffffff;
}

#resultsPerPartyTable {
    margin: auto;
}

#resultsPerPartyTable td {
    border: 1px solid #ddd;
}

#resultsPerPartyTable th {
    border: 1px solid #ddd;
}