/*
CSS for index.html
@hugoalexandremf
*/

body {
    background-image: url('images/blackMarble.jpg');
}

/*
----------------------------------------------------Texto----------------------------------------------------
*/
#texto{
    color: white;
    font-size:18px;
    font-family: Times;
    margin-top: 6%;
    margin-left: 7%;
    margin-right: 7%;
}
/*
----------------------------------------------------Texto----------------------------------------------------
*/